<template>
  <b-table hover responsive="sm" :fields="fields" :items="items" caption-top>
    <!-- A virtual column -->
    <template #cell(index)="data">
      {{ data.index + 1 }}
    </template>
    <template #cell(amount)="data">
      {{ formatAmount(data.value) }}
    </template>

    <!-- rate column -->
    <template #cell(rate)="data">
      <span v-if="data.value">{{ data.value }} %</span>
      <span v-else>---</span>
    </template>

    <!-- Total column -->
    <template #cell(total)="data">
      {{ formatAmount(data.value) }}
    </template>

    <!-- Payment date format column -->
    <template #cell(payment_date)="data">
      <span v-if="data.item.payment_date"
        >{{ data.item.payment_date }}, {{ data.item.payment_date_format }}</span
      >
    </template>
    <!-- Column: Actions -->
    <template #cell(actions)="data">
      <div class="text-nowrap">
        <b-button
          v-if="data.index == 0"
          variant="flat-danger"
          class="btn-icon rounded-circle"
          @click="openModal('edit', data.item)"
        >
          <feather-icon
            :id="`payment-row-${data.item.id}-icon`"
            icon="EditIcon"
            size="16"
          />
        </b-button>
        <b-tooltip
          v-if="data.index == 0"
          title="Modifier Payment"
          :target="`payment-row-${data.item.id}-icon`"
        />
      </div>
    </template>
    <template #table-caption>
      <b-card class="card-app-design">
        <b-card-title class="mt-1 mb-75">
          <span class="mr-2"> État de paiements </span>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            @click="openModal('add')"
          >
            Ajouter Paiement
          </b-button>
          <validation-observer
            ref="refFormObserverPayment"
            #default="{ invalid }"
          >
            <b-modal
              id="modal-payment"
              cancel-variant="outline-secondary"
              :ok-title="
                paymentModal.action == 'edit' ? 'Modifier' : 'Enregistrer'
              "
              :ok-disabled="invalid || isLoading"
              cancel-title="Annuler"
              centered
              :title="
                paymentModal.action == 'edit'
                  ? 'Modifier Paiement'
                  : 'Ajouter Paiement'
              "
              v-model="paymentModal.is_opened"
              @ok="onSubmit"
            >
              <b-form>
                <!-- label -->
                <validation-provider
                  #default="{ errors }"
                  name="Motif"
                  rules="required"
                >
                  <b-form-group
                    label="Intitulé"
                    label-for="label"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      v-model="payment.label"
                      id="label"
                      type="text"
                      placeholder="Intitulé du paiement"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- amount -->
                <validation-provider
                  #default="{ errors }"
                  name="Montant"
                  rules="required|min-amount:0"
                >
                  <b-form-group
                    label="Montant (XOF)"
                    label-for="amount"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      v-model="payment.amount"
                      id="amount"
                      type="number"
                      placeholder="Le montant de paiement"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <div v-if="paymentModal.action === 'add'">
                  <!-- payment method -->
                  <validation-provider
                    #default="{ errors }"
                    name="Mode de paiement"
                    rules="required"
                  >
                    <b-form-group
                      label="Mode de paiement"
                      label-for="payment_method"
                    >
                      <v-select
                        v-model="currPaymentMethod"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="paymentMethods"
                        label="name"
                        :clearable="false"
                        input-id="payment_method"
                        placeholder="Sélectionner la méthode de paiement..."
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- concerned account -->
                  <validation-provider
                    #default="{ errors }"
                    name="Compte de dépôt"
                    rules="required"
                    v-if="currPaymentMethod.of_type === 'bank'"
                  >
                    <b-form-group
                      label="Compte de dépôt"
                      label-for="concerned_sub_cash_register_1"
                    >
                      <v-select
                        v-model="payment.sub_cash_register"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="accountsList"
                        label="name"
                        :clearable="false"
                        :reduce="(sub_cr) => sub_cr.uid"
                        input-id="concerned_sub_cash_register_1"
                        placeholder="Sélectionner le compte de dépôt..."
                        :disabled="isLoading"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <!-- sub cash register -->
                  <validation-provider
                    #default="{ errors }"
                    name="Caisse dépôt"
                    rules="required"
                    v-if="
                      currPaymentMethod.of_type === 'mobile' ||
                      currPaymentMethod.of_type === 'cash'
                    "
                  >
                    <b-form-group
                      label="Caisse de dépôt"
                      label-for="concerned_sub_cash_register_2"
                    >
                      <v-select
                        v-model="payment.sub_cash_register"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="subCashRegistersList"
                        label="name"
                        :clearable="false"
                        :reduce="(a) => a.uid"
                        input-id="concerned_sub_cash_register_2"
                        placeholder="Sélectionner la caisse de dépôt..."
                        :disabled="isLoading"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>

                <!-- rate -->
                <validation-provider
                  #default="{ errors }"
                  name="Taux"
                  rules="required"
                >
                  <b-form-group
                    label="Taux (%)"
                    label-for="rate"
                    description="Insérer le taux de paiement en %"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      v-model="payment.rate"
                      id="amount"
                      type="number"
                      min="0"
                      max="500"
                      placeholder="..."
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- Payment date -->
                <validation-provider
                  #default="{ errors }"
                  name="Date de Paiement"
                  rules="required"
                >
                  <b-form-group
                    label="Date de Paiement"
                    label-for="payment_date"
                    :state="errors.length > 0 ? false : null"
                  >
                    <flat-pickr
                      id="payment_date"
                      v-model="payment.payment_date"
                      placeholder="Sélectionnez la date de paiement"
                      :config="flatConfig"
                      class="form-control"
                    />

                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <!-- details -->
                <validation-provider #default="{ errors }" name="Détails">
                  <b-form-group
                    label="Détails sur le paiement"
                    label-for="details"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-textarea
                      v-model="payment.details"
                      id="details"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form>
            </b-modal>
          </validation-observer>
        </b-card-title>
        <b-card-text class="font-small-2 mb-2">
          Voir la liste des différents paiements effectués sur le projet
        </b-card-text>

        <div class="design-planning-wrapper">
          <!-- <div class="design-planning">
            <p class="card-text mb-25">Dernier Paiement</p>
            <h6 class="mb-0">--------</h6>
          </div> -->
          <div class="design-planning">
            <p class="card-text mb-25">Total Reçu</p>
            <h6 class="mb-0">{{ formatAmount(totalReceived) }}</h6>
          </div>
          <div class="design-planning">
            <p class="card-text mb-25">Vendu</p>
            <h6 class="mb-0">{{ formatAmount(dutiesFree) }}</h6>
          </div>
        </div>
      </b-card>
    </template>
  </b-table>
</template>

<script>
import store from "@/store";
import axios from "@axios";
import { mapGetters } from "vuex";
import flatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";

import {
  BTable,
  BCard,
  BCardTitle,
  BCardText,
  BButton,
  BTooltip,
  BModal,
  VBModal,
  BForm,
  BFormInvalidFeedback,
  BFormInput,
  BFormTextarea,
  BFormGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { required, positive, minAmount } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

const requestOptions = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};

export default {
  name: "PaymentStatusView",
  components: {
    BTable,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BTooltip,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormInvalidFeedback,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      paymentModal: { is_opened: false, action: "add" },
      fields: [
        { key: "index", label: "Nº" },
        { key: "label", label: "Intitulé" },
        { key: "amount", label: "Montant (XOF)" },
        { key: "rate", label: "Taux" },
        { key: "payment_date", label: "Date de Paiement", sortable: true },
        "total",
        "actions",
      ],
      required,
      minAmount,
      payment: {
        label: "",
        rate: null,
        amount: null,
        payment_date: null,
        sub_cash_register: null,
        details: "",
      },
      currPaymentMethod: { of_type: null },
      budget: {},
      flatConfig: {
        wrap: true,
        dateFormat: "d-m-Y",
        maxDate: new Date(),
        locale: French, // locale for this instance only
      },
    };
  },
  watch: {
    isBankPM: {
      handler: "getPaymentMethods",
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("project", {
      items: "paymentsStatus",
    }),
    isBankPM() {
      return this.currPaymentMethod.of_type === "bank";
    },
    paymentMethods() {
      return store.state.subCashRegister.payment_methods;
    },
    accountsList() {
      return store.getters["fundHistory/accounts"];
    },
    subCashRegistersList() {
      const cashRegister = store.getters["fundHistory/cashRegister"];
      const subCRs = cashRegister.sub_cash_registers;

      if (subCRs.length <= 0) return [];

      return subCRs.filter((s_cr) => s_cr.is_default);
    },
    totalReceived() {
      return this.items.reduce(
        (prevItem, currItem) => prevItem + currItem.amount,
        0
      );
    },
    projectId() {
      return this.$route.params.id;
    },
    dutiesFree() {
      if (!this.budget.duties_free) return 0;
      return this.budget.duties_free;
    },
  },
  created() {
    this.getPaymentsStatusList();
    this.fetchProjectBudget();
  },
  methods: {
    openModal(action, payment = {}) {
      this.$store.dispatch("subCashRegister/fetchSubCashRegisterProperties", {
        props: ["payment_methods"],
      });
      if (action == "edit") {
        this.getPaymentStatus(payment);
        this.paymentModal.action = "edit";
      } else {
        this.paymentModal.action = "add";
      }

      this.paymentModal.is_opened = true;
    },
    async getPaymentMethods(isBank) {
      this.isLoading = true;
      this.payment.sub_cash_register = null;

      try {
        if (isBank) {
          await store.dispatch("fundHistory/getAccounts");
        } else {
          await store.dispatch("fundHistory/loadCashRegister");
        }
      } catch (e) {
        //
      } finally {
        this.isLoading = false;
      }
    },
    fetchProjectBudget() {
      axios
        .get(`finances/real-tracking/${this.projectId}`, requestOptions)
        .then(({ data }) => {
          this.budget = data.resource?.budget;
        })
        .catch(({ response }) => {
          if (response.status === 400) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Suivi Budget",
                text: response.data.error,
                icon: "AlertTriangleIcon",
                variant: "info",
              },
            });
            this.budget = {};
          }
        });
    },
    getPaymentsStatusList() {
      const params = {
        projectId: this.projectId,
      };
      store.dispatch("project/fetchPaymentsStatus", params);
    },
    getPaymentStatus(payment) {
      axios
        .get(
          `finances/projects/${this.projectId}/payments-status/${payment.id}`
        )
        .then(({ data }) => {
          this.payment = data.resource;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erreur de récupération",
              text: error.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$refs.refFormObserverPayment.validate().then((success) => {
        this.isLoading = true;
        if (success) {
          const payload = {
            label: this.payment.label,
            rate: this.payment.rate,
            details: this.payment.details,
            amount: parseInt(this.payment.amount),
            payment_date: this.payment.payment_date,
            method: this.currPaymentMethod.uid ?? null,
            sub_cr: this.payment.sub_cash_register,
          };
          const handleRequest = this.payment.id
            ? axios.put(
                `finances/projects/${this.projectId}/payments-status/${this.payment.id}`,
                payload
              )
            : axios.post(
                `finances/projects/${this.projectId}/payments-status`,
                payload
              );
          handleRequest
            .then(({ data }) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Etat de Paiement",
                  text: data?.message,
                  icon: "AlertTriangleIcon",
                  variant: "success",
                },
              });
              this.paymentModal.is_opened = false;
              this.getPaymentsStatusList();
              this.payment = { amount: null, payment_date: null };
              this.isLoading = false;
            })
            .catch(({ response }) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Etat de Paiement",
                  text:
                    response.status === 422
                      ? response?.data.error
                      : response?.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
              this.isLoading = false;
              return;
            });
        }
      });
    },
    formatAmount(amount) {
      return Intl.NumberFormat("fr-FR").format(amount);
    },
  },
};
</script>
